import {
  BadGateway,
  BadRequestError,
  Conflict,
  Forbidden,
  NotFound,
  UnexpectedError,
} from 'domain/errors';
import { CreateTopicConfig } from 'domain/usecases/sacConfig/remote';

import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteCreateTopicConfig implements CreateTopicConfig {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateTopicConfig.Model>;

  constructor(url: string, httClient: HttpClient<CreateTopicConfig.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateTopicConfig.Params,
  ): Promise<CreateTopicConfig.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
