import React, { useMemo } from 'react';

import { OrgInfos } from 'domain/usecases/org/remote';
import { History } from 'main/routes';
import { IconDefaultUser } from 'presentation/base/icons';
import { Actions, Container } from './style/StyledOrgNavigator';

import { Avatar } from '../UI/avatar';
import { Button } from '../UI/buttons';
import Translator from '../i18n/Translator';

interface OwnProps {
  nav: Function;
  active: number;
  creating?: number;
  org?: OrgInfos;
  haveUpdated?: () => void;
}

const OrgNavigator: React.FC<OwnProps> = ({
  nav,
  active,
  creating,
  org,
  haveUpdated,
}) => {
  const generalData = useMemo(() => {
    return (
      <Button
        id="button_generalData"
        variant={active === 1 ? 'primary' : 'secundary'}
        onClick={() => {
          nav(1);
          if (active === 2 && window.location.pathname.includes('unit')) {
            History.back();
          }
        }}
        active={active === 1}
      >
        {Translator('Dados gerais')}
      </Button>
    );
  }, [active, nav]);

  const sectors = useMemo(() => {
    return (
      <Button
        id="button_sectors"
        variant={active === 2 || creating === 2 ? 'primary' : 'secundary'}
        onClick={() => nav(2)}
        active={creating === 2 || active === 2}
      >
        {Translator('Setores')}
      </Button>
    );
  }, [active, creating, nav]);

  const settings = useMemo(() => {
    return (
      <Button
        id="button_settings"
        variant={active === 3 ? 'primary' : 'secundary'}
        onClick={() => {
          nav(3);
          if (active === 2 && window.location.pathname.includes('unit')) {
            History.back();
          }
        }}
        active={active === 3}
        permissionsV2={org?.permission?.update}
      >
        {Translator('Configurações')}
      </Button>
    );
  }, [active, nav, org]);

  const billing = useMemo(() => {
    return (
      <Button
        id="button_billing"
        variant={active === 4 ? 'primary' : 'secundary'}
        onClick={() => {
          nav(4);
          if (active === 2 && window.location.pathname.includes('unit')) {
            History.back();
          }
        }}
        active={active === 4}
        permissionsV2={org?.permission?.readBilling}
      >
        {Translator('Faturamento')}
      </Button>
    );
  }, [active, nav, org]);

  const sac = useMemo(() => {
    return (
      <Button
        id="button_sac"
        variant={active === 5 ? 'primary' : 'secundary'}
        onClick={() => nav(5)}
        active={active === 5}
      >
        {Translator('SAC - Atende Fácil')}
      </Button>
    );
  }, [active, nav, org]);

  return (
    <Container>
      <Avatar
        fullName={org?.name}
        src={creating === 1 ? IconDefaultUser : String(org?.logo)}
        size="205px"
        showUploadButton={creating === 0}
        haveUpdated={haveUpdated}
        isCircular={false}
        hasBorder
      />
      <Actions>
        {(!creating || creating === 1) && generalData}
        {(!creating || creating === 2) && sectors}
        {!creating && settings}
        {!creating && billing}
        {!creating && sac}
      </Actions>
    </Container>
  );
};

export default OrgNavigator;
