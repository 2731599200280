import {
  BadGateway,
  BadRequestError,
  Conflict,
  Forbidden,
  NotFound,
  UnexpectedError,
} from 'domain/errors';
import { GetSacConfig } from 'domain/usecases/sacConfig/remote';

import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteGetSacConfig implements GetSacConfig {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetSacConfig.Model>;

  constructor(url: string, httClient: HttpClient<GetSacConfig.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (params: GetSacConfig.Params): Promise<GetSacConfig.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.orgId}/CONFIG`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
