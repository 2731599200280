import React from 'react';

import {
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import Popover from 'presentation/components/UI/popover/Popover';
import { useListTopics } from 'presentation/hooks/sac/listTopics';
import { PopoverContent, PopoverItem } from '../styles';
import { iModalSupportInformation } from '../types';
import { HotlineDataTable, SupportInformationData } from './types';

type TableActionsProps = {
  row: {
    original: HotlineDataTable;
  };
  active?: boolean;
};

export const TableActions: React.FC<TableActionsProps> = ({
  row,
  active = false,
}) => {
  const { id, ...data } = row.original;

  const values = data as unknown as SupportInformationData;

  const dataToModal: iModalSupportInformation = {
    ...values,
    email: values.contactEmail,
    phones: values.contactPhones,
    schedule: values.schedule,
  };

  const { handleUpdateTopic, handleDeleteTopic } = useListTopics();
  const renderOptions = () => {
    return (
      <PopoverContent>
        <PopoverItem onClick={() => console.log('edit')}>
          <Icon name="edit" size="h6" />
          <Typography variant="b3_14medium">Editar</Typography>
        </PopoverItem>
        <PopoverItem onClick={openDisableConfirmationModal}>
          <Icon name="cancel" size="h6" />
          <Typography variant="b3_14medium">Desabilitar assunto</Typography>
        </PopoverItem>
        <PopoverItem onClick={() => handleDeleteTopic(id)}>
          <Icon name="delete" size="h6" />
          <Typography variant="b3_14medium">Excluir</Typography>
        </PopoverItem>
      </PopoverContent>
    );
  };

  const openSupportInformationModal = () => {
    makeReduxActiveMessage().active({
      active: 'supportInformation',
      data: dataToModal,
    });
  };

  const openEnableConfirmationModal = () => {
    makeReduxActiveMessage().active({
      active: 'helpLine',
      data: {
        variant: 'ENABLED',
      },
      actionOk: () =>
        handleUpdateTopic({
          id,
          active: true,
        }),
    });
  };

  const openDisableConfirmationModal = () => {
    makeReduxActiveMessage().active({
      active: 'helpLine',
      actionOk: () =>
        handleUpdateTopic({
          id,
          active: false,
        }),
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.5rem',
      }}
    >
      {active ? (
        <>
          <Tooltip content="Ver informações">
            <IconButton
              variant="primary"
              icon="visibility"
              onClick={openSupportInformationModal}
              size="sm"
            />
          </Tooltip>
          <Tooltip content="Mais opções">
            <Popover content={renderOptions()}>
              <IconButton variant="primary" icon="more_vert" size="sm" />
            </Popover>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip content="Habilitar assunto">
            <IconButton
              variant="primary"
              icon="check_circle"
              onClick={openEnableConfirmationModal}
              size="sm"
            />
          </Tooltip>
          <Tooltip content="Excluir">
            <IconButton
              variant="destructive"
              icon="delete"
              onClick={() => handleDeleteTopic(id)}
              title="Excluir"
              size="sm"
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};
