import { Button, Icon, Typography } from '@wisecare-tech/design-system-web';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import React from 'react';
import { closeModal } from 'utils/closeModal';
import {
  Container,
  ContainerModal,
  Content,
  Footer,
  Header,
} from './styles/StyledHelpLine';

export interface propsHelpLine {
  message: iMessage;
}

export const HelpLine: React.FC<propsHelpLine> = ({ message }) => {
  const msgName = MessageOptions.helpLine;

  const { active, actionOk, data } = message;

  return (
    <>
      {msgName === active ? (
        <ContainerModal>
          <Container>
            <Content>
              <Header variant={data?.variant}>
                {data?.variant === 'ENABLED' ? (
                  <Icon name="info" size="h5" color="blue-7" />
                ) : (
                  <Icon name="warning" size="h5" color="orange-7" />
                )}
              </Header>
              <Typography variant="t1_18semibold">
                {data?.variant === 'ENABLED'
                  ? 'Habilitar linha de atendimento'
                  : 'Desabilitar linha de atendimento'}
              </Typography>
              <Typography variant="b1_16regular" style={{ color: '#656a6e' }}>
                {data?.variant === 'ENABLED'
                  ? 'Se você realizar esta ação, todos os serviços associados a esta linha de atendimento serão ativados imediatamente. Isso inclui o atendimento a clientes e qualquer processo automatizado vinculado. Tem certeza que deseja prosseguir?'
                  : 'Ao realizar está ação, todos os serviços associados a esta linha de atendimento serão interrompidos imediatamente. Isso inclui o atendimento a clientes e qualquer processo automatizado vinculado. Tem certeza que deseja prosseguir?'}
              </Typography>
            </Content>
            <Footer>
              <Button variant="secondary" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant={
                  data?.variant === 'ENABLED' ? 'primary' : 'destructive'
                }
                onClick={() => {
                  actionOk?.();
                  closeModal();
                }}
              >
                {data?.variant === 'ENABLED'
                  ? 'Sim, quero habilitar'
                  : 'Sim, quero desabilitar'}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      ) : null}
    </>
  );
};
