import React from 'react';
import { SacConfigProvider } from 'presentation/hooks/sac/createConfig';
import { SacSettings } from 'presentation/pages/SacSettings';

export const SacConfigFactory: React.FC = () => {
  return (
    <SacConfigProvider>
      <SacSettings />
    </SacConfigProvider>
  );
};
