import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  SortButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@wisecare-tech/design-system-web';
import React from 'react';
import { useListTopics } from 'presentation/hooks/sac/listTopics';
import { SkeletonContainer } from '../styles';
import { ItemSkeleton } from './itemSkeleton';
import { TableActions } from './tableActions';
import { HotlineDataTable } from './types';

const columns: ColumnDef<HotlineDataTable>[] = [
  {
    accessorKey: 'subject',
    header: ({ column }) => {
      return <SortButton column={column as any}>Assunto</SortButton>;
    },
  },
  {
    accessorKey: 'attendant',
    header: ({ column }) => {
      return <SortButton column={column as any}>Atendentes</SortButton>;
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => <TableActions row={row} active />,
  },
];

const HotlineTable: React.FC = () => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const { isLoading, topics, overview } = useListTopics();

  const table = useReactTable({
    data: topics.map(topic => ({
      ...topic,
      attendant: topic.specialists.length,
      subject: topic.title,
    })),
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
  });

  if (isLoading) {
    return (
      <SkeletonContainer>
        {Array.from<number>({ length: overview.active ?? 1 }).map(
          (_, index) => (
            <ItemSkeleton key={_} />
          ),
        )}
      </SkeletonContainer>
    );
  }
  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map(headerGroup => (
          <>
            {headerGroup.headers.map(header => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map(row => (
            <TableRow key={row.id} selected={row.getIsSelected()}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="tw-h-24 tw-text-center"
            >
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default HotlineTable;
