import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { iOneUser, iStore } from 'domain/interfaces/models';
import { makeRemoteCreateTopicConfig } from 'main/factories/usecases/sacConfig/CreateTopicConfig';
import { makeRemoteGetSacConfig } from 'main/factories/usecases/sacConfig/GetSacConfig';
import { createTopicConfigSchema } from 'validation/sac/createTopConfig';
import { DaysAbbreviation, DaysOfWeek } from './types';

export const daysMap: Record<DaysAbbreviation, DaysOfWeek> = {
  SU: {
    label: 'SU',
    fullLabel: 'Domingo',
    value: 0,
    abbreviate: 'Dom',
  },
  MO: {
    label: 'MO',
    fullLabel: 'Segunda',
    value: 1,
    abbreviate: 'Seg',
  },
  TU: {
    label: 'TU',
    fullLabel: 'Terça',
    value: 2,
    abbreviate: 'Ter',
  },
  WE: {
    label: 'WE',
    fullLabel: 'Quarta',
    value: 3,
    abbreviate: 'Qua',
  },
  TH: {
    label: 'TH',
    fullLabel: 'Quinta',
    value: 4,
    abbreviate: 'Qui',
  },
  FR: {
    label: 'FR',
    fullLabel: 'Sexta',
    value: 5,
    abbreviate: 'Sex',
  },
  ST: {
    label: 'ST',
    fullLabel: 'Sábado',
    value: 6,
    abbreviate: 'Sáb',
  },
};

export const daysOfWeek: DaysOfWeek[] = [
  {
    label: 'SU',
    fullLabel: 'Domingo',
    value: 0,
    abbreviate: 'Dom',
  },
  {
    label: 'MO',
    fullLabel: 'Segunda',
    value: 1,
    abbreviate: 'Seg',
  },
  {
    label: 'TU',
    fullLabel: 'Terça',
    value: 2,
    abbreviate: 'Ter',
  },
  {
    label: 'WE',
    fullLabel: 'Quarta',
    value: 3,
    abbreviate: 'Qua',
  },
  {
    label: 'TH',
    fullLabel: 'Quinta',
    value: 4,
    abbreviate: 'Qui',
  },
  {
    label: 'FR',
    fullLabel: 'Sexta',
    value: 5,
    abbreviate: 'Sex',
  },
  {
    label: 'ST',
    fullLabel: 'Sábado',
    value: 6,
    abbreviate: 'Sáb',
  },
];

interface ConfigTopicContextValue {
  scheduleDays: {
    day: string;
    hours: { start: string; finish: string }[];
  }[];
  scheduleType: 'FIXED' | 'FLEXIBLE' | undefined;
  loading: boolean;
  removePhone: (index: number) => void;
  getAttendant: (attendantId: number) => iOneUser | undefined;
  submitValues: () => void;
  handleBackStep: () => void;
  handleAddAttendants: (attendantId: number) => void;
  handleRemoveAttendants: (attendantId: number) => void;
  setPhoneNumber: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  createNewPhone: () => void;
  removeHour: (day: DaysAbbreviation, index: number) => void;
  handleSelectDay: (day: DaysOfWeek) => void;
  handleScheduleType: (type: 'FIXED' | 'FLEXIBLE') => void;
  createNewHour: (day: DaysAbbreviation) => void;
  setStartFinish: (
    value: string,
    day: DaysAbbreviation,
    field: 'start' | 'finish',
    indexHours?: number,
  ) => void;
}

const ConfigTopicContext = createContext<ConfigTopicContextValue | undefined>(
  undefined,
);

export function useTopicConfig(): ConfigTopicContextValue {
  const context = useContext(ConfigTopicContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

export const ConfigTopicProvider: React.FC = ({ children }) => {
  const [hasAlreadyConfig, setHasAlreadyConfig] = useState(false);

  const [loading, setLoading] = useState(false);

  const { user } = useSelector((store: iStore) => store.auth);
  const { records: users } = useSelector((store: iStore) => store.user);

  const history = useHistory();

  const form = useForm({
    resolver: yupResolver(createTopicConfigSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      contactPhones: [''],
      contactEmail: '',
      config: {
        scheduleType: 'FIXED',
      },
      org: user?.org.id ?? 1,
      schedule: [],
      attendants: [],
      step: 1,
    },
  });

  const { watch, setValue, getValues, trigger, unregister } = form;

  const scheduleDays = watch('schedule') ?? [];
  const scheduleType = watch('config.scheduleType');

  const getSacConfig = useCallback(async () => {
    const getRemoteSacConfig = makeRemoteGetSacConfig();
    setLoading(true);
    try {
      const response = await getRemoteSacConfig.get({
        orgId: String(user?.org.id) ?? '1',
      });
      if (response) {
        setHasAlreadyConfig(true);

        setValue('schedule', response.config.schedule);
        setValue('contactEmail', response.config.supportEmail);
        setValue('contactPhones', response.config.phones);

        setValue('config.scheduleType', response.config.scheduleType);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setValue, user]);

  const handleSelectDay = (day: DaysOfWeek) => {
    const isSelected = scheduleDays.some(
      selected => selected.day === day.label,
    );

    trigger('schedule');

    if (isSelected) {
      setValue(
        'schedule',
        scheduleDays.filter(schedule => schedule.day !== day.label),
      );
    } else {
      setValue('schedule', [
        ...scheduleDays,
        {
          day: day.label,
          hours: [
            {
              start:
                scheduleType === 'FIXED'
                  ? scheduleDays?.[0]?.hours?.[0]?.start
                  : '',
              finish:
                scheduleType === 'FIXED'
                  ? scheduleDays?.[0]?.hours?.[0]?.finish
                  : '',
            },
          ],
        },
      ]);
    }
  };

  const setStartFinish = (
    value: string,
    day: DaysAbbreviation,
    field: 'start' | 'finish',
    indexHours = 0,
  ) => {
    if (scheduleType === 'FIXED') {
      const newSchedule = scheduleDays?.map(schedule => {
        schedule.hours[indexHours] = {
          ...schedule.hours[indexHours],
          [field]: value,
        };
        return schedule;
      });

      setValue('schedule', newSchedule);
      return;
    }

    const newSchedule = scheduleDays?.map(schedule => {
      if (schedule.day === day) {
        schedule.hours[indexHours] = {
          ...schedule.hours[indexHours],
          [field]: value,
        };
      }
      return schedule;
    });

    setValue('schedule', newSchedule);
  };

  const setPhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const phones = getValues('contactPhones') ?? [];
    phones[index] = e.target.value;
    setValue('contactPhones', phones);
  };

  const createNewPhone = () => {
    const newPhones = getValues('contactPhones') ?? [];
    newPhones.push('');
    setValue('contactPhones', newPhones);
  };

  const removePhone = (index: number) => {
    unregister(`contactPhones.${index}`);
  };

  const createNewHour = (day: DaysAbbreviation) => {
    const newSchedule = scheduleDays?.map(schedule => {
      if (schedule.day === day || scheduleType === 'FIXED') {
        schedule.hours.push({ start: '', finish: '' });
      }
      return schedule;
    });

    setValue('schedule', newSchedule);
  };

  const removeHour = (day: DaysAbbreviation, index: number) => {
    const newSchedule = scheduleDays?.map(schedule => {
      if (schedule.day === day || scheduleType === 'FIXED') {
        schedule.hours.splice(index, 1);
      }
      return schedule;
    });

    setValue('schedule', newSchedule);
  };

  const addCodeCountry = (phone: string): string => {
    const valueFormatted = phone.replace(/\D/g, '');
    if (!valueFormatted.startsWith('55')) {
      return `+55${valueFormatted}`;
    }
    if (valueFormatted.startsWith('55')) {
      return `+${valueFormatted}`;
    }
    return valueFormatted;
  };

  const handleScheduleType = (type: 'FIXED' | 'FLEXIBLE') => {
    const newSchedule = scheduleDays.map(schedule => ({
      ...schedule,
      hours: [
        {
          start: hasAlreadyConfig ? scheduleDays?.[0]?.hours?.[0]?.start : '',
          finish: hasAlreadyConfig ? scheduleDays?.[0]?.hours?.[0]?.finish : '',
        },
      ],
    }));
    setValue('schedule', newSchedule);
    setValue('config.scheduleType', type);
  };

  const handleBackStep = () => {
    const { step } = getValues();

    if (step === 1) {
      history.goBack();
      return;
    }

    setValue('step', step - 1);
  };

  const submitValues = async () => {
    const { step, ...values } = getValues();
    const createSacConfig = makeRemoteCreateTopicConfig();

    if (step === 1) {
      setValue('step', 2);
      return;
    }

    try {
      await createSacConfig.create({
        ...values,
        schedule: values.schedule.map(schedule => ({
          day: schedule.day,
          hours: schedule.hours.map(hour => ({
            ...hour,
            start: `${hour.start}:00`,
            finish: `${hour.finish}:00`,
          })),
        })),
        org: user?.org.id ?? 1,
        contactPhones: values.contactPhones?.map(addCodeCountry),
        attendants: values.attendants ?? [],
      });
      toast.success('Configurações salvas com sucesso', {
        onClose: () => history.goBack(),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddAttendants = (attendantId: number) => {
    const attendants = getValues('attendants') ?? [];
    attendants.push(attendantId);
    setValue('attendants', attendants);
  };

  const handleRemoveAttendants = (attendantId: number) => {
    const attendants = getValues('attendants') ?? [];
    const newAttendants = attendants.filter(
      attendant => attendant !== attendantId,
    );
    setValue('attendants', newAttendants);
  };

  const getAttendant = (attendantId: number): iOneUser | undefined => {
    return users.find(attendant => attendant.id === attendantId);
  };

  const configValue: ConfigTopicContextValue = {
    loading,
    scheduleType,
    scheduleDays,
    getAttendant,
    submitValues,
    handleBackStep,
    handleAddAttendants,
    handleRemoveAttendants,
    removePhone,
    createNewPhone,
    setPhoneNumber,
    removeHour,
    createNewHour,
    handleSelectDay,
    handleScheduleType,
    setStartFinish,
  };

  useEffect(() => {
    getSacConfig();
  }, [getSacConfig]);

  return (
    <FormProvider {...form}>
      <ConfigTopicContext.Provider value={configValue}>
        {children}
      </ConfigTopicContext.Provider>
    </FormProvider>
  );
};
