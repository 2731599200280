import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { IconCloseWhite } from 'presentation/base/icons';
import { closeModal } from 'utils/closeModal';
import { makeRemoteQueueSacRequest } from 'main/factories/usecases/sacRequest/QueueSacRequestFactory';
import { makeRemoteJoinSacRequest } from 'main/factories/usecases/sacRequest/JoinSacRequestFactory';
import { History } from 'main/routes';
import { makeRemoteListSacRequestById } from 'main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import { makeRemoteLeftSacRequest } from 'main/factories/usecases/sacRequest/LeftSacRequestFactory';
import { toast } from 'react-toastify';
import { Container as GlobalContainer } from '../Background';
import { ownProps } from '../interface';
import {
  Container,
  Content,
  Title,
  Body,
  ContentBody,
  User,
  Footer,
} from './styles/StyledSacProfessionalReadyModal';
import { Avatar } from '../../UI/avatar';
import { AvatarImage } from '../../avatar';
import { Button } from '../../UI';

const SacProfessionalReady: React.FC<ownProps> = ({ message }) => {
  const [notResponse, setNotResponse] = React.useState<boolean>(false);
  const [seconds, setSeconds] = useState(30);
  const [topic, setTopic] = useState<string>('');

  const msgName = MessageOptions.sacProfessionalReady;

  const { active, actionCancel, actionOk, data } = message;
  console.log('>>>', data, seconds);

  const token = new URLSearchParams(window.location.search).get('token');

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('seconds', seconds);
      if (seconds) setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    if (!seconds) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (data?.request && token) {
      makeRemoteListSacRequestById()
        .listById({
          request: data?.request,
          token,
        })
        .then(response => {
          console.log('##response', response);
          setTopic(response.topic?.title);
        })
        .catch(() => {
          console.log('error');
        });
    }
  }, [data?.request, token]);

  return (
    <>
      {msgName === active ? (
        <GlobalContainer style={{ zIndex: 99 }}>
          <Container>
            <Content>
              <Title>
                <h1>
                  {!seconds
                    ? 'Você foi chamado, mas não respondeu.'
                    : 'Chegou sua vez! O atendente está aguardando.'}
                </h1>
                <span>
                  {!seconds
                    ? 'Se ainda deseja atendimento, clique em “Voltar para a fila.” Caso contrário, você pode encerrar o atendimento.'
                    : 'O profissional abaixo ficará responsável pelo seu atendimento e já está na videochamada. Clique no botão abaixo para começar.'}
                </span>
              </Title>
              <Body>
                <ContentBody>
                  <AvatarImage size="large" src={data?.avatar} />
                  <User>
                    <h2>{data?.fullName}</h2>
                    <span>{topic}</span>
                  </User>
                </ContentBody>
                <Footer>
                  {!seconds ? (
                    <>
                      <Button
                        variant="secundary"
                        type="button"
                        size="fit-content"
                        onClick={() => {
                          closeModal();
                          makeRemoteLeftSacRequest()
                            .left({
                              request: data?.request,
                              token:
                                new URLSearchParams(window.location.search).get(
                                  'token',
                                ) || '',
                            })
                            .then(() => {
                              toast.success(
                                'Cancelamento efetuado com sucesso.',
                              );
                              History.getHistory().push(
                                `/sac/subject?token=${new URLSearchParams(
                                  window.location.search,
                                ).get('token')}`,
                              );
                            })
                            .catch(() => {
                              toast.error('Erro ao cancelar solicitação.');
                            });
                        }}
                      >
                        Encerrar atendimento
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        size="fit-content"
                        onClick={() => {
                          if (data?.request)
                            makeRemoteQueueSacRequest()
                              .queue({
                                request: data?.request,
                                token:
                                  new URLSearchParams(
                                    window.location.search,
                                  ).get('token') || '',
                              })
                              .then(() => {
                                toast.success(
                                  'Solicitação efetuada com sucesso.',
                                );
                              })
                              .catch(() => {
                                toast.error('Erro ao solicitar.');
                              })
                              .finally(() => {
                                closeModal();
                              });
                        }}
                      >
                        Voltar para a fila
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="primary"
                      type="button"
                      size="large"
                      onClick={() => {
                        if (data?.request)
                          makeRemoteJoinSacRequest()
                            .join({
                              request: data?.request,
                              token:
                                new URLSearchParams(window.location.search).get(
                                  'token',
                                ) ?? '',
                            })
                            .then(res => {
                              History.getHistory().push(
                                `/join?t=${
                                  res.roomName
                                }&u=${new URLSearchParams(
                                  window.location.search,
                                ).get('u')}&c=${false}&m=${false}&sr=${
                                  data?.request
                                }&sessionName=${
                                  res.sessionName
                                }&ak=${new URLSearchParams(
                                  window.location.search,
                                ).get('token')}`,
                              );
                            })
                            .finally(() => {
                              closeModal();
                            });
                      }}
                    >
                      {`Começar atendimento (${seconds})`}
                    </Button>
                  )}
                </Footer>
              </Body>
            </Content>
          </Container>
        </GlobalContainer>
      ) : null}
    </>
  );
};

export default SacProfessionalReady;
