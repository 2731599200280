import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AtendeFacil } from 'presentation/pages';
import { iStore } from 'domain/interfaces/models';
import { makeRemoteJoinSacSpecialist } from '../../usecases/sacSpecialist/JoinSacSpecialistFactory';
import { makeRemoteLeftSacSpecialist } from '../../usecases/sacSpecialist/LeftSacSpecialistFactory';

export const MakeAtendeFacilFactory: React.FC = () => {
  const { user } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    if (user) makeRemoteJoinSacSpecialist().join({ userId: user.id });

    return () => {
      if (user) makeRemoteLeftSacSpecialist().left({ userId: user.id });
    };
  }, []);

  return <AtendeFacil />;
};
