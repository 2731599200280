import React from 'react';
import { ListTopicsProvider } from 'presentation/hooks/sac/listTopics';
import { Sac } from 'presentation/pages/Sac';

export const SacListHotlinesFactory: React.FC = () => {
  return (
    <ListTopicsProvider>
      <Sac />
    </ListTopicsProvider>
  );
};
